// Table colors
.linkBox {
  padding: 20px;
  background: #0073b7;
  margin-bottom: 20px;

  h3 {
    padding: 0;
    margin: 0;
    color: white;
  }
}

.isLate {
  background: rgba(255, 0, 0, 0.1) !important;
  &:hover {
    background: rgba(255, 0, 0, 0.2) !important;
  }
}
.shouldAlert{
  background: rgba(255, 192, 0, 0.1) !important;
  &:hover {
    background: rgba(255, 192, 0, 0.2) !important;
  }
}

.dropdown-menu>li a{
display: block;
padding: 3px 20px;
clear: both;
font-weight: 400;
line-height: 1.42857143;
color: #333;
white-space: nowrap;
  &:hover {
    background: #e1e3e9 !important;
  }
}

.modal {
  z-index: 30000;
}

.main-header {
  z-index: 860;
}
@media (min-width: 768px){
  .modal-dialog {
    margin: 60px auto;
  }
}